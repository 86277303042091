<template>
  <div class="Cartiglio" :style="{ backgroundImage: 'url(https://media.tok.tv/alive/assets/static/img/filigrana.svg)' }">
    <a :href="`https://eosauthority.com/account/${author}?network=jungle`"
       target="_blank"
       class="Cartiglio__author" >
      <img src="https://media.tok.tv/alive/assets/static/img/cockade.svg"> @{{ author }}
    </a>
    <div class="Cartiglio__title">
      {{ title }}
    </div>
    <div class="Cartiglio__description">
      {{ description }}
    </div>
    <div class="Cartiglio__footer">
      <div class="Cartiglio__footer__owner">
        <div class="Cartiglio__footer__owner__icon">
          <img src="https://media.tok.tv/alive/assets/static/img/nft.png">
        </div>
        <template v-if="owner">
          <a
              :href="`https://eosauthority.com/account/${owner}?network=jungle`"
              target="_blank"
              class="Cartiglio__footer__owner__info"
          >
            <div class="Cartiglio__footer__owner__info__label">
              Owner
            </div>
            <div class="Cartiglio__footer__owner__info__value">
              {{ owner ? `@${owner}` : 'Unassigned' }}
            </div>
          </a>
        </template>
        <template v-else>
          <div
              class="Cartiglio__footer__owner__info"
          >
            <div class="Cartiglio__footer__owner__info__label">
              Owner
            </div>
            <div class="Cartiglio__footer__owner__info__value">
              {{ owner ? `@${owner}` : 'Unassigned' }}
            </div>
          </div>
        </template>
      </div>
      <a
          :href="`https://eosauthority.com/transaction/${contract}?network=jungle`"
          class="Cartiglio__footer__code"
          :title="contract"
          target="_blank"
      >
        {{ contract.substr(0, 20) }}...
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import get from 'lodash.get';
export default {
  name: 'Cartiglio',
  computed: {
    ...mapState([
      'nftInfo',
    ]),
    author() {
      const meta = get(this.nftInfo, 'metadata', '{}');
      const parsed = JSON.parse(meta);
      return get(parsed, 'artist');
    },
    title() {
      return get(this.nftInfo, 'title', '');
    },
    description() {
      return get(this.nftInfo, 'description', '');
    },
    owner() {
      const owner = get(this.nftInfo, 'owner.walletDTO.id', '');
      return owner ? owner : false;
    },
    contract() {
      return get(this.nftInfo, 'contractId', '');
    }
  },
}
</script>

<style lang="scss" scoped>
  .Cartiglio {
    font-family: 'IBM Plex Sans', sans-serif;
    z-index: 1000;
    position: absolute;
    bottom: 100px;
    right: 20px;
    width: 400px;
    height: 200px;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 15px;
    padding: 20px;
    color: white;
    display: flex;
    flex-direction: column;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    @media screen and (max-width: 600px) {
      bottom: 50px;
    }
    @media screen and (max-width: 400px) {
      width: calc(100% - 40px);
      right: 20px;
    }
    &__author {
      display: flex;
      align-items: center;
      font-size: 12px;
      color: white;
      font-weight: 700;
      img {
        width: 15px;
        margin-right: 10px;
      }

    }
    &__title {
      text-transform: uppercase;
      font-size: 18px;
      padding: 10px 0;
    }
    &__description {
      font-size: 12px;
    }
    &__footer {
      flex: auto;
      display: flex;
      align-items: flex-end;
      flex-direction: row;
      justify-content: space-between;
      &__owner {
        font-size: 12px;
        display: flex;
        flex-direction: row;
        align-items: center;
        &__icon {
          img {
            width: 25px;
          }
        }
        &__info {
          padding-left: 10px;
          display: flex;
          flex-direction: column;
          color: #fff;
          &__label {
            font-size: 8px;
          }
          &__value {
            padding-top: 4px;
            font-weight: 700;
          }
        }
      }
      &__code {
        color: #FFF;
        font-size: 12px;
      }
    }
  }
</style>
