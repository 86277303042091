<template>
  <div v-if="p" class="w-100 fixed position-relative">
    <Player360
      @tapped="writingDelay()"
      v-if="showVideo360 || showVideo"
      :url="video.stream_hls"
      :id="video.video_id"
      :cover="false"
      :token="loginInfo.token"
      @exit="exit()"
      :options="[]"
      :showOptions="true"
      @handleVideo="() => {}"
      :type="video.video_type"
      :leftFix="true"
      :plain="showVideo || videoType === 'normal'"
      :noClose="true"
      :autoplay="false"
      :reloadOnLock="true"
      @play="setCartiglio(false)"
      @finish="setCartiglio(true)"
      :noFullBtn="hasFullBtn"
    />
    <transition
        appear
        enterActiveClass="animate__animated animate__fadeIn"
        leaveActiveClass="animate__animated animate__fadeOut"
    >
      <Cartiglio v-if="cartiglio" />
    </transition>
  </div>
</template>

<script>

import { mapActions, mapMutations, mapState } from 'vuex';
import { get } from 'lodash';
import Player360 from '@/components/Player360.vue';
import Cartiglio from './Cartiglio';

export default {
  name: 'NFT',
  props: ['id'],
  components: {
    Cartiglio,
    Player360,
  },
  data() {
    return {
      videoType: '360',
      nftInfo: false,
      playerActive: false,
      cartiglio: true,
      p: false,
      get,
      video: false,
      showVideo360: false,
      showVideo: false,
      rbv: false,
      interval: false,
      writing: false,
      writingTo: false,
    };
  },
  metaInfo() {
    return {
      title: this.nftInfo.title,
      meta: [
        { property: 'og:title', content: this.nftInfo.title},
        { property: 'og:description', content: this.nftInfo.description},
        { property: 'og:image', content: this.nftCoverUrl},
      ]
    }
  },
  methods: {
    ...mapActions(['getMe', 'getPlans', 'getNftCode', 'getNftInfo']),
    ...mapMutations(['appReady', 'redirectToIP', 'loadRedeem', 'setLoginInfo', 'setNFTInfo']),
    setCartiglio(status) {
      this.cartiglio = status;
    },
    loadUrl(url) {
      this.p = true;
      this.cartiglio = true;
      this.video = {
        cover: this.nftCoverUrl,
        stream_hls: url,
        title: this.nftTitle,
        video_360: true,
        video_type: "vod"
      };
      this.showVideo360 = true;
      this.showVideo = false;
    },
    exit() {
      this.p = false;
      this.cartiglio = false;
    },
    load() {
      const self = this;
      self.getNftInfo(this.id)
          .then((data) => {
            // eslint-disable-next-line no-debugger
            debugger;
            self.setNFTInfo(data);
            self.nftInfo = data;
            const url = get(data, 'videoUrl')
            const upMetadata = get(data, 'metadata')
            const metadata = upMetadata ? JSON.parse(upMetadata) : {};
            self.videoType = get(metadata, 'videoType');
            self.loadUrl(url);
            self.appReady(true);
          })
          .catch((e) => {
            console.log(e);
          });
    },
  },
  computed: {
    ...mapState([
      'me',
      'loginInfo',
      'locale',
      'api',
      'nftCode',
      'browser',
      'md',
    ]),
    hasFullBtn() {
      const embedded = get(this, '$route.query.embed', false);
      return embedded;
    },
    nftCoverUrl() {
      return get(this.nftInfo, 'coverUrl', false);
    },
    nftStreamUrl() {
      return get(this.nftInfo, 'videoUrl', false);
    },
    nftTitle() {
      return get(this.nftInfo, 'title', false);
    }
  },
  mounted() {
    this.load();
  },
};
</script>

<style lang="scss" scoped>
  .ComaCose {
    height: 50%;
    background-size: cover;
    background-position: top center;
    display: flex;
    flex-direction: column;
    &__logos {
      display: flex;
      align-items: center;
      justify-content: center;
      &__logo {
        padding: 10px;
        img {
          width:80px;
          height: auto;
          &.alive {
            width:60px;
          }
        }
      }
    }
    &__head {
      color: #FFFFFF;
      &__mainTitle {
        margin: 0 auto;
        font-size: 150px;
        width: 100%;
        max-width: 1000px;
        &__infos{
          display: flex;
          @media screen and (max-width: 600px) {
            flex-direction: column;
          }
          flex-direction: row;
          justify-content: space-between;
          padding: 0 25px;
          &__what {
            @media screen and (max-width: 600px) {
              text-align: center;
              margin-bottom: 10px;
            }
            text-shadow: 0 0 5px rgba(0, 0, 0, 1);
            font-size: 25px;
          }
          &__where {
            @media screen and (max-width: 600px) {
              text-align: center;
            }
            text-shadow: 0 0 5px rgba(0, 0, 0, 1);
            font-size: 25px;
            text-align: right;
          }
        }
      }
    }
    &__play{
      flex: auto;
      display: flex;
      justify-content: space-around;
      align-items: center;
      max-width: 1000px;
      width: 100%;
      margin: 0 auto;
      @media screen and (max-width: 600px) {
        flex-direction: column;
        flex: unset;
      }
      flex-direction: row;
      &__trailer {
        position: relative;
        margin: 30px 30px 0 30px;
        font-size: 18px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        width: 220px;
        height: calc(220px * 0.75);
        padding: 15px 30px;
        background-color: rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        border: 2px solid #FFFFFF;
        color: #FFFFFF;
        cursor: pointer;
        font-weight: bold;
        text-shadow: 0 0 10px rgba(0, 0, 0, 1);
        background-size: cover;
        background-position: center;
      }
      &__nft {
        position: relative;
        margin: 30px 30px 0 30px;
        font-size: 18px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        width: 220px;
        height: calc(220px * 0.75);
        padding: 15px 30px;
        background-color: rgba(0, 0, 0, 0.25);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        border: 2px solid #FFFFFF;
        color: #FFFFFF;
        cursor: pointer;
        font-weight: bold;
        text-shadow: 0 0 10px rgba(0, 0, 0, 1);
        background-size: cover;
        background-position: center;
      }
    }
  }
</style>
